html, body, #root, #theme {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
#theme {
  background-color: #fff;
  &.dark-mode {
    background-color: #131313;
  }
}
p, div.p, span, div, button, a, pre, label {
  font-family: sans-serif;
  font-size: 14px;
  color: #222;
  .dark-mode & {
    color: #ccccdd;
  }
}
p, div.p {
  margin: 12px 0;
}
label, .label {
  color: #222;
  .dark-mode & {
    color: #ccccdd;
  }
}
pre {
  font-family: 'Courier New', Courier, monospace;
}
input {
  border-radius: 2px;
  height: 23px;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 1px 5px;
  color: #161616;
  .dark-mode & {
    background-color: #161616;
    border-color: #777;
    color: #f0f0f0;
  }
}
hr {
  border: none;
  border-top: 1px solid #666;
  margin: 15px 0;
}
h1 {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana,
    sans-serif;
  font-size: 14px;
  color: #333;
  margin: 0;

  .dark-mode & {
    color: #ddd;
  }
}

/* try to make buttons look like links: (see jsx-a11y/anchor-is-valid eslint rule) */
button.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

/* try to make links look like buttons: (because this is what Link creates) */
a.button, button, a.button:visited {
  padding: 6px 12px;
  text-decoration: none;
  margin: 0px;
  border-radius: 3px;
  cursor: pointer;
  line-height: 14px;  // important to make buttons look exactly the same as (button-ified) links
  display: inline-block;  // fixes height discrepancies between <Link> and <button>
  background-color: initial;
  color: #fff;
  background-color: #4cd074;
  border: none;
  .dark-mode & {
    color: #ccccdd;
    background-color: initial;
    border: 1px solid #2b9f4e;
  }

  &.orange {
    background-color: #ec932f;
  }
  &.blue {
    background-color: #5b7ed5;
    .dark-mode & {
      background-color: initial;
      border-color: #4e6cb6;
    }
  }
  &.red {
    background-color: #a71818;
    .dark-mode & {
      background-color: initial;
      border-color: #801212;
    }
  }
  &.disabled {
    color: #ccc;
    .dark-mode & {
      color: #555;
      border-color: #777;
    }
  }

  & > i.fa {
    font-size: 14px;
    margin-right: 5px;
    &.fa-plus {
      font-size: 12px;
    }
  }
}


// button {
//   background-color: #4cd074;
//   color: #fff;
//   border: 1px solid #45bc69;
//   height: 27px;
//   cursor: pointer;
//   border-radius: 3px;
//   padding: 1px 4px;
//   font-size: 12px;
//   font-weight: 600;
// }
// button:disabled {
//   color: #ccc;
// }
// button:hover {
//   background-color: #4cd074;
// }

.changelog {
  h3 {
    margin: 0 0 10px;
    span.created-at {
      font-weight: normal;
    }
  }
  pre {
    margin: 10px 10px 20px;
  }
}

// generic list - for bots, persons and similar:
table.list {
  border-collapse: collapse;
  margin-bottom: 20px;

  td, th {
    border: 1px solid #999999;
    padding: 10px 20px;
  }

  th.sortable {
    cursor: pointer;

    i.fa {
      margin-left: 10px;
    }
  }
}

.fa-exclamation-triangle {
  margin-right: 10px;
  color: #aa2200;
  .dark-mode & {
    color: #dd2200;
  }
}

.frame {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #eee;
  background-color: #fff;
  margin-bottom: 10px;
  .dark-mode & {
    background-color: #161616;
    border: 1px solid #333333;
  }
}

.fa-stack.notif-badge-dot {
  color: #e8e55b;
  font-size: 16px;

  .fa.fa-circle {
    color: #fff;
    .dark-mode & {
      color: #161616;
    }
  }
}

form .framed-field {
  border: 1px solid #666;
  margin-top: 20px;
  border-radius: 2px;
  position: relative;
  padding: 20px 10px 10px;

  & > label:first-of-type {
    display: inline-block;
    position: absolute;
    top: -6px;
    left: 10px;
    background-color: #151515;
    padding: 0 10px 0;
  }
}