.dockable-container {
  position: relative;
  overflow-x: hidden;
}
i.toggle-collapse-button {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
  text-align: center;
  z-index: 2;
  border-radius: 3px;
  font-size: 12px;
  line-height: 30px;
  margin-right: 4px;
  color: #335533;
}
