.editable-label {
  i.fa-pencil {
    color: #0f428a;
    margin-left: 2px;
    .dark-mode & {
      color: #5e7fac;
    }
  }
  i.fa-check {
    color: #009900;
    margin-left: 5px;
  }
  i.fa-close {
    color: #990000;
    margin-left: 2px;
  }
}