.dashboard-view {
  .dashboard-info {
    margin: 0px 0px 25px;
    display: flex;

    .widget-sorting {
      margin-left: auto;

      button {
        margin-left: 10px;
      }
    }
  }

  .header-widgets {
    margin-bottom: 20px;
    padding-right: 20px;
  }
}
