div.admin_first {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div.info {
    margin-bottom: 20px;
    width: 250px;
    max-width: 90vw;
    min-width: 150px;
    color: #222;
    .dark-mode & {
      color: #bbb;
    }

    strong {
      font-style: italic;
      font-weight: bold;
    }


    &.error {
      margin: 10px 0 10px;
      .fa-exclamation-triangle {
        color: #660000;
      }
    }
  }
}