form {
  display: flex;
  flex-direction: column;

  .field {
    margin-bottom: 10px;

    label {
      margin-bottom: 3px;
      font-size: 12px;
      display: block;
    }
    input:not([type=checkbox]) {
      width: 250px;
      max-width: 90vw;
      min-width: 150px;

      &[type=radio] {
        width: initial;
        min-width: initial;
      }
    }
    p.hint {
      font-size: 11px;
      font-style: italic;
      margin: 5px 0;
      max-width: 250px;
    }

    .radio_option_container {
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        margin-right: 8px;
        display: flex;
        align-items: center;

        & > * {
          margin: 0;
          padding: 0;
          font-size: 12px;
        }
      }

      input[type=radio] {
        margin-right: 5px;
      }
    }
  }
  button {
    margin-top: 10px;
    max-width: 200px;
  }
  .nested-field {
    padding-left: 10px;
  }
}
