a, a:visited {
  color: #3b60b3;
  text-decoration: none;
  .dark-mode & {
    color: #7ea3d5;
  }
}
a:hover { color: #678bdc; text-decoration: none; }

.hcenter {
  display: flex;
  align-content: center;
}
.vcenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

header {
  margin-bottom: 20px;
  img {
    width: 70%;
  }
}

.burger {
  margin: 10px auto 0 20px;
  & > i.fa.fa-bars {
    margin: 0 2px 0 0;
  }
}

.navigation {
  margin: 10px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);

  div.spacer {
    flex-grow: 1;
  }
  a {
    margin: 10px 15px 0;
    position: relative;
    &.sub {
      margin-left: 40px;
    }
    &.space-before {
      margin-top: 20px;
    }
    i.fa {
      width: 1.2em;
    }
  }
  .bottom {
    padding: 10px 15px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .version-info {
      font-style: italic;
      font-size: 10px;
      color: #666;
    }
    .api-doc {
      margin: 0;
      font-size: 10px;
      color: #666;
    }
    .color-scheme-switch {
      font-size: 10px;
    }
  }

  .back-logout-buttons {
    width: 100%;
    display: flex;

    button.unselect-account {
      margin-left: 15px;
    }
    button.logout {
      margin-left: 15px;
      margin-right: auto;
    }
    i.fa {
      margin: 0;
    }
  }

  .account-name, label {
    padding: 5px 5px 5px;
    color: #111;

    .dark-mode & {
      color: #ddd;
    }
  }

  .fa-stack.notif-badge-dot {
    position: absolute;
    right: -3px;
    top: -3px;
    height: initial;
    line-height: initial;
    width: 1em;
  }
}

.notifications {
  flex-grow: 0;
  display: flex;

  & > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    & > li {
      padding: 20px 20px;
      margin: 20px 20px 0;
      border-radius: 5px;
      border: 1px solid #ccc;
      position: relative;

      &.error {
        background-color: #ffeeee;
        .dark-mode & {
          background-color: initial;
          border-color: #801212;
        }
      }
      &.warning {
        background-color: #ffeedd;
        .dark-mode & {
          background-color: initial;
          border-color: #f90;
        }
      }
      &.info {
        background-color: #ddffdd;
        .dark-mode & {
          background-color: initial;
          border-color: #2b9f4e;
        }
      }

      i.fa-close {
        position: absolute;
        padding: 10px;
        top: -5px;
        right: -5px;
      }
    }
  }
}

// .with-bg-image {
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-image: url("/bg/bgtest3.jpg");
// }

.content {
  flex: 1;
  display: flex;
  margin: 20px;

  & > .centered {
    margin: auto;
  }
}
