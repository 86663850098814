form .widget-form {
  margin-top: 20px;

  label.widget-type {
    display: flex;
    align-items: center;
    text-transform: capitalize;

    input[type=radio] {
      margin: 0;
    }

    i.fa.widget-type {
      padding: 0 5px 0;
      font-size: 15px;
      margin-bottom: 0;
    }
  }

  .widget-type-form {
    padding-left: 25px;

    input {
      height: 20;
      min-width: 300;
    }
  }
}