.loading-component {
  padding: 40px;

  &.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 99999999;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .message {
      margin-left: 20px;
    }
  }

  i {
    font-size: 64px;
  }
}

