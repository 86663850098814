.netflow-navigation-widget {
  .radios {
    display: flex;
  }
  .entities-interfaces {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .loading-component {
      display: inline-block;
      padding: 0 20px;
      i.fa {
        font-size: 20px;
      }
    }
  }
}