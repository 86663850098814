.widget-dialog-container {
  position: relative;

  .widget-dialog-overlay {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #000000;
  }
  .widget-dialog {
    position: absolute;
    left: 20px;
    top: 0;
    box-sizing: border-box;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 10px 20px 20px #cccccc;
    border: 1px solid #eeeeee;
    border-top: none;
  }
}

.repinchy-content {
  position: relative;
}

.chart {
  background-color: #fff;
  position: relative;
  float: left;
  .dark-mode & {
    background-color: #1a1a1a;
  }

  .status {
    position: absolute;
    right: 9px;
    top: 9px;

    i.fa-circle-o-notch {
      color: #666;
      font-size: 30px;
    }
    i.fa-exclamation-triangle {
      color: #660000;
      margin: 5px;
      font-size: 20px;
    }
  }

  .y-axis-label {
    font-family: 'Comic Sans MS', cursive, sans-serif;
    font-size: 13px;
    text-anchor: end;
    stroke: none;
    fill: #333333;
    .dark-mode & {
      fill: #ddd;
    }
  }

  .y-axis-unit {
    font-family: 'Comic Sans MS', cursive, sans-serif;
    font-size: 13px;
    text-anchor: end;
    stroke: none;
    font-weight: bold;
    fill: #333333;
    .dark-mode & {
      fill: #ddd;
    }
  }

  .tooltip-popup {
    .tooltip-indicator {
      shape-rendering: crispEdges;
      stroke: #e3e3e3;
      stroke-width: 1px;
      .dark-mode & {
        stroke: #ff0000;
      }
    }

    .tooltip-popup-content {
      border-radius: 7px;
      padding: 10px 20px;
      font-size: 12px;
    }
  }
}

.time-interval-selector {
  button {
    margin-top: 10px;
    margin-left: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #000;

    .dark-mode & {
      border: 1px solid #444;
      background-color: #333;
      color: #eee;
      }
  }
}