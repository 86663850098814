.chart-form {
  div.serie {
    border: 1px solid #aaaaaa;
    padding: 20px 30px;
    margin: 10px 20px;
  }
  .Select div {
    width: 250px;
  }
}

.matching-paths {
  border: 1px solid #cccccc;
  padding: 5px 25px 10px 15px;
  background-color: #dadaff;
  font-size: 13px;
  max-height: 200px;
  overflow: auto;
  min-width: 200px;
  margin-bottom: 30px;
  margin-left: 10px;

  .dark-mode & {
    background-color: #222;
    border: 1px solid #777;
    color: #222;
    * {
      color: #ccc;
    }
  }

  .info {
    font-style: italic;
  }
  .renaming {
    margin-left: 20px;
  }
}