.timestamp-x-axis {
  shape-rendering: crispEdges;
  stroke-width: 1;
  stroke: #999999;
  .dark-mode & {
    stroke: #666666;
  }

  & > rect {
    fill: #ffffff;
    .dark-mode & {
      fill: #1a1a1a;
    }
  }

  line.minor-tick {
    shape-rendering: crispEdges;
    stroke-width: 1;
    stroke: #999999;
    .dark-mode & {
      stroke: #666666;
    }
  }

  line.major-tick {
    shape-rendering: crispEdges;
    stroke-width: 1;
    stroke: #999999;
    .dark-mode & {
      stroke: #666666;
    }
  }

  text.label {
    font-family: 'Comic Sans MS', cursive, sans-serif;
    font-size: 12px;
    text-anchor: middle;
    stroke: none;
    fill: #333333;
    .dark-mode & {
      fill: #aaaaaa;
    }

    &.interval {
      text-anchor: start;
    }
    &.secondary-interval.right {
      text-anchor: end;
    }
    &.secondary-interval.left {
      text-anchor: start;
    }
  }
}
