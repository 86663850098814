.top-n {
  color: #333;
  .dark-mode & {
    color: #fafafa;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -50px;
    opacity: 0.4;
    .loading-component .fa {
      font-size: 48px;
    }
  }

  .color {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin: 0 10px 0 0;
  }

  .label {
    font-size: 15px;
  }
  .value {
    font-size: 30px;
    margin-left: 15px;
  }
  .unit {
    font-size: 25px;
    margin-left: 3px;
  }
  .percent {
    font-size: 15px;
    margin-left: 3px;
  }

  .total {
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
  }

  .data {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.display-list {
      width: 100%;
      & > * {
        flex-grow: 1;
      }
    }
    &.display-pie {
      justify-content: space-around;
    }

    .list {
      .entry {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        .percent-bar {
          margin-left: auto;
          padding-bottom: 6px;
          position: relative;

          .wrapper {
            width: 70px;
            border: 1px solid #444;
            height: 17px;
            position: relative;

            .bar {
              background-color: #36c;
              height: 100%;
              margin-left: auto;
            }
            .text-wrapper {
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;

              .text {
                color: #ccd;
              }
            }
          }
        }
      }
    }

    .pie {

      svg {
        max-width: 150px;
      }
    }
  }

  .time {
    margin-top: 10px;
    font-size: 16px;

    .when {
      font-size: 12px;
      .dark-mode & {
        color: #ccc;
      }
    }
  }
}
