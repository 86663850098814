
.widget {
  background-color: #1a1a1a;

  &.fullscreen {
    width: auto !important;
    height: auto !important;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background-color: #ffffff;
    .dark-mode & {
      background-color: #1a1a1a;
    }
  }

  .widget-title {
    padding: 5px 20px;
    display: flex;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    .dark-mode & {
      border-bottom: 1px solid #555;
    }

    h1 {
      flex-grow: 1;
      line-height: 20px;
      padding: 0;
      font-weight: normal;
      .dark-mode & {
        color: #ccc;
      }

      i {
        font-size: 12px;
        color: hsl(215, 80%, 30%);
        margin-left: 5px;
      }
    }
    i {
      display: block;
      flex-grow: 0;
      margin-left: 10px;
      margin-top: 2px;

      &.fa-trash {
        color: hsl(0, 80%, 30%);
      }
      &.fa-edit {
        margin-top: 2px;
        color: hsl(215, 80%, 30%);
      }
      &.fullscreen {
        margin-left: 11px;
        font-size: 13px;
        padding-top: 1px;
        color: hsl(215, 80%, 30%);
      }
      .dark-mode &.fa-trash,
      .dark-mode &.fa-edit,
      .dark-mode &.fullscreen
      {
        color: #ccc;
      }
    }

    .widget-button.disabled {
      color: #445;
    }
  }
}

.widget-content {
  padding: 15px 20px 20px;
}

.page {
  width: 100%;
}

.sorting-overlay {
  background-color: #182c1f;
  opacity: 0.9;
  position: absolute;
  left: 0;
  top: 0;
}

.react-grid-layout .react-grid-item > .react-resizable-handle::after {
  right: 13px;
  bottom: 13px;
}
