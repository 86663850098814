.auth-form-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .box {
    padding: 50px;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .grafolean {
      width: 250px;
      margin-top: 30px;
      display: flex;
      justify-content: right;
      align-items: flex-start;


      .grafolean-logo {
        width: 150px;
        margin-right: 50px;
      }
    }

    div.form {
      border-left: 1px solid #ccc;
      padding: 20px 50px;
      display: flex;
      flex-direction: column;
      max-width: 400px;

      .dark-mode & {
        border-left: 1px solid #555;
      }

      .field {
        margin-bottom: 10px;

        label {
          margin-bottom: 3px;
          display: block;
          &, a {
            font-size: 14px;
          }
        }
        input:not([type=checkbox]) {
          width: 250px;
          max-width: 90vw;
          min-width: 150px;
        }
      }
      button {
        margin-top: 10px;
      }
    }

    .error-msg {
      margin: 20px 0 0;
      color: #660000;
    }

    .signup-text {
      margin-top: 20px;
    }
  }

  .version {
    position: fixed;
    right: 10px;
    bottom: 3px;
  }
}

@media screen and (max-width: 700px) {
  .auth-form-page .box  {
    flex-direction: column !important;

    .grafolean {
      margin-top: none;
    }

    div.form {
      border-left: none;
    }
  }
}
