.breadcrumbs {
  padding: 13px 30px;
  margin: 10px 20px 0;
  opacity: 0.95;

  .breadcrumb-item {
    a {
      color: #222;
      .dark-mode & {
        color: #ddd;
      }
    }
    b {
      font-weight: normal;
      color: #222;
      .dark-mode & {
        color: #ddd;
      }
    }
  }

  .separator {
    font-size: 8px;
    padding: 0 10px;
  }
}