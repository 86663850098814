.warning_page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > div {
    margin-bottom: 20px;
    max-width: 500px;

    .fa-exclamation-triangle {
      margin-right: 10px;
      color: #660000;
    }
  }
}